<template>
  <!--begin::Input group-->
  <div class="row mb-6">
    <!--begin::Label-->
    <label
      class="col-lg-4 col-form-label fw-bold fs-6"
      :class="{ required: required }"
      >{{ label }}</label
    >
    <!--end::Label-->

    <!--begin::Col-->
    <div class="col-lg-8 fv-row">
      <div class="d-flex justify-center align-center">
        <div style="width: 256px; height: 256px; padding: 0px 3px">
          <Field
            type="hidden"
            :name="name"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            v-model="imageLogo"
          />
          <div v-if="imageLogo" style="position: relative">
            <img class="image-logo" :src="imageLogo" alt="" />
            <button @click="deleteImage" class="btn btn-danger bt-delete">
              x
            </button>
          </div>
          <button
            v-else
            type="button"
            class="form-control btn btn-outline-secondary btn-new-icon"
            data-bs-toggle="modal"
            data-bs-target="#modal_new_icon"
          >
            <img src="@/assets/picture.png" class="icon-ima" /><br />
            แนบรูป
          </button>
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage :name="name" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Input group-->
  <CropImage
    :aspectRatio="aspectRatio"
    :height="height"
    :width="width"
    @clicked="satImageLogo"
    ref="emptyimage"
  ></CropImage>
</template>

<script>
import CropImage from "@/components/modals/CropImage.vue";
import { ref } from "vue";
import { ErrorMessage, Field } from "vee-validate";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    data: {
      default: [],
    },
    image: {
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: 1 / 1,
    },
    height: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 100,
    },
  },
  components: {
    CropImage,
    ErrorMessage,
    Field,
  },
  setup(props) {
    const imageLogo = ref(props.image);
    const emptyimage = ref(null);
    function satImageLogo(image) {
      imageLogo.value = image;
    }

    function deleteImage() {
      imageLogo.value = "";
      emptyimage.value.cancel();
    }

    return {
      imageLogo,
      satImageLogo,
      deleteImage,
      emptyimage,
    };
  },
};
</script>

<style scoped>
.bt-delete {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px !important;
  border-radius: 0px;
}
.image-logo {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
  border: 1px solid #eff2f5;
}
</style>