<template>
  <!--begin::Input group-->
  <div class="row mb-6">
    <!--begin::Label-->
    <label
      class="col-lg-4 col-form-label fw-bold fs-6"
      :class="{ required: required }"
      >{{ label }}</label
    >
    <!--end::Label-->

    <!--begin::Col-->
    <div class="col-lg-8 fv-row">
      <Field
        :name="name"
        class="form-control h-auto form-control-solid py-4 px-8"
        as="select"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      >
        <option value="" selected disabled>โปรดเลือก</option>
        <option
          v-for="l in data"
          :key="l[k]"
          :value="l[k]"
        >
          {{ l[d] }}
        </option>
        <!--:selected="value && value.includes(role.id)"-->
      </Field>

      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <ErrorMessage :name="name" />
        </div>
      </div>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Input group-->
</template>

<script>
import { ErrorMessage, Field } from "vee-validate";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    data: {
      default: [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    l: {
      type: [String],
      default: "item",
    },
    k: {
      type: [String, Number],
      default: "id",
    },
    d: {
      type: String,
      default: "name",
    },
  },
  components: {
    ErrorMessage,
    Field,
  },
};
</script>

<style>
</style>