import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useStores() {

  const router = useRouter();

  const getStores = async (page) => {
    return await ApiService.query("entrepreneur_stores",page);
  };

  const getStore = async (id) => {
    return await  ApiService.get("entrepreneur_stores/" + id);
  };

  const destroyStore = async (id) => {
    await ApiService.delete("entrepreneur_stores/" + id);
  };

  const stoerStore = async (data) => {
    await ApiService.post("entrepreneur_stores", data);
    await router.push({ name: "apps.travels.entrepreneur.store.index" });
  };

  const updateStore = async (data) => {
    await ApiService.put("entrepreneur_stores/" + data.id, data);
    await router.push({
      name: "apps.travels.entrepreneur.store.edit",
      params: { id: data.id },
    });
  };


  
  const getStoreImage = async (id) => {
    return await ApiService.get("entrepreneur_stores_get_image/" + id);

  };

  const updateStoreImage = async (data) => {
    return ApiService.post("entrepreneur_stores_image", data);
    
  };

  const destroyStoreImage = async (id) => {
    await ApiService.delete("entrepreneur_stores_image/" + id);
  };


  

  return {
    getStores,
    getStore,
    destroyStore,
    stoerStore,
    updateStore,
    getStoreImage,
    updateStoreImage,
    destroyStoreImage
  };
}
